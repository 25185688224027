<template>
  <PageWithLayout>
    <Search
      :searchDataList.sync="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.searchDataList.value2 ? '검색결과가 없습니다' : '회원정보를 검색해주세요'"
      @onClickRow="rowId => viewModel.onClickRow(rowId)"
      @onSearch="viewModel.onSearch()">
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import UserListViewModel from '@/views/service/user/viewModel/UserListViewModel'

export default {
  name:'UserSearchList',
  components:{
    PageWithLayout,
    Search,
    Board,
    Button,
    IconSvg,
  },
  beforeMount(){
    this.viewModel.userAdminType = this.$route.meta.lnbMenuData.id
  },
  data(){
    return{
      viewModel: new UserListViewModel(),
    }
  },
}
</script>

<style scoped>
</style>